var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "c-section" },
      [
        _c("h1", { staticClass: "c-title2 c-title2--mb16" }, [
          _vm._v("お問い合わせが完了しました"),
        ]),
        _vm._m(0),
        _c("p", { staticClass: "p-contact__notes" }, [
          _vm._v(
            " 数日経っても回答メールが届かない場合は、大変お手数ですが、再度お問い合わせをお願いいたします。 "
          ),
        ]),
        _c(
          "Button",
          {
            staticClass: "p-contact__action",
            attrs: {
              href: _vm.$customUrlScheme.page("root/home/1"),
              "style-type": "tertiary",
              fluid: "",
              flat: "",
            },
          },
          [_vm._v(" トップへ戻る ")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "p-contact__thanks" }, [
      _vm._v(" お問い合わせいただきありがとうございました。"),
      _c("br"),
      _vm._v(
        " 問い合わせ内容につきましては、担当者からご返信いたしますので、しばらくお待ちください。 "
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }